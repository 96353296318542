.st-link {
    width: 100%;
    font-size: 0.875rem;
    color: var(--primary-color);
    cursor: pointer;
    text-decoration: none;
}

.st-link-btn {
    background: none;
    border: none;
    text-align: start;
    padding: 0;
}
