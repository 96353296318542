.left-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .left-container {
    padding-left: 4rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.responsive-link {
  text-decoration: none;
  font-size: 2rem;
  font-weight: bold;
  width: fit-content;
  padding-bottom: 1rem;
  color: var(--primary-color);
}

.ul-responsive {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 50rem;
}

.li-responsive {
  display: flex;
  align-items: center;
  padding: 0 0.2rem;
}
