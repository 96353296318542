
@media (max-width:500px) {
  :root{
      font-size: 10px;
  }
}

@media (max-width:992px) {
    :root{
        font-size: 12px;
    }
    .responsiveForm {
        flex-direction: column;
    }
    .responsive-row {
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        align-items: flex-start;
        padding-bottom: 1rem;
    }
}

.responsiveForm {
    display: flex;
}

.column {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
}

.responsive-row {
    display: flex;
    justify-items: flex-start;
    align-items: flex-start;
    padding-bottom: 0.75rem;
}

.column-centered-container {
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    border: none;
    background: none;

}

.column-centered-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

