:root{
    font-family: "Source Sans Pro", "Roboto", "Myriad Set Pro", "Lucida Grande",
    "Helvetica Neue", "Helvetica", "Arial", "Verdana", "sans-serif";
    font-size: 16px;
    /*For colors, please follow the same pattern used in https://www.primefaces.org/primereact/colors/*/
    --primary-color: #1589EE;
    --secondary-color: #54698D;
    --text-color-secondary: #54698D;
    --surface-100: #F4F6F9;
    --surface-350: #E0E5EE;
    --blue-650: #0E6EC2;
    --blue-850: #273171;
    --blue-150: #169bd5;
    --blue-660: #2262A9;
    --gray-150: #F4F6F9;
    --gray-170: #D8DDE6;
    --gray-180: #a8b7c7;
    --gray-185:  #c4c4c4;
    --gray-190: #868e96;
    --gray-500: #555555;
    --gray-600: #757575;
    --gray-650: #495057;
    --gray-850: #061C40;
    --gray-860: #061C3F;

    --pink-750: #990067;
    --green-550: #00B189;
    --green-570: #12A780;
    --success: var(--green-550);
    --warn: #ffb75d;
    --danger: #EE3124;
    --info:  #c4c4c4;
    --alert: #EE3124;
    --error: var(--alert);
    --hamburger-menu: #69b3e7;
    --text-table: #4b5563;
}

h1 {
    font-size: 1.3125rem;
    font-weight: 600;
    color: var(--gray-900);
    margin: 0 0 0.313rem;
}

h5 {
    font-weight: 600;
    color: var(--gray-900);
    margin: 0;
}

textarea{
    resize: none;
}

* {
    font-family: "Source Sans Pro", "Roboto", "Myriad Set Pro", "Lucida Grande",
    "Helvetica Neue", "Helvetica", "Arial", "Verdana", "sans-serif";
    font-size: 1rem;
    font-weight: lighter;
    -webkit-font-smoothing: antialiased;
}
/*Date ranges*/
#from > input {
    padding-left: 12px;
    padding-top: 7px;
}

#to > input {
    padding-left: 12px;
    padding-top: 7px;
}

input.p-inputtext {
    font-family: "Source Sans Pro", "Roboto", "Myriad Set Pro", "Lucida Grande",
    "Helvetica Neue", "Helvetica", "Arial", "Verdana", "sans-serif";
    padding:0 0 0.2rem 5px;
    outline: none;
    color: #000000;
}

input.p-inputtext:enabled:focus {
    border: none;
    border-bottom: 2px solid var(--gray-180);
    box-shadow: none;
}



.p-column-title {
    background-color: white;
    color: var(--text-color-secondary);
    font-weight: 600;
    padding-bottom: 0;
    text-transform: uppercase;
    font-size: 0.875rem;
}

.p-datatable .p-datatable-tbody > tr > td{
    padding: 0.4rem 1rem 0.4rem 1rem
}

.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead {
    background-color: white;
}

.p-datatable-scrollable .p-datatable-thead>tr>th,
.p-datatable-scrollable .p-datatable-tbody>tr> td, .p-datatable-scrollable .p-datatable-tfoot>tr>td {
    flex:initial;
    font-size: 0.875rem;
    overflow-wrap: anywhere;

}

.p-datatable .p-datatable-thead > tr > th {
    padding: 0 1rem;
    background-color: white;
}

.p-datatable .p-datatable-tbody > tr:first-child {
    border: 1px solid var(--gray-170);
}


/* Default table border */
td{
    border-bottom: 1px solid var(--gray-170);
    border-top: 1px solid var(--gray-170);
}

/* Makes border of table visible */
td:first-child {
    border-left: 1px solid var(--gray-170);
}

td:last-child {
    border-right: 1px solid var(--gray-170);
}

/* Add border to last child of the table */
.p-datatable .p-datatable-tbody > tr:last-child > td {
    border-bottom: 1px solid var(--gray-170);
}

.p-datatable-thead > tr:first-child > th {
    padding-top: 1rem;
}

.p-datatable-thead > tr:last-child > th {
    padding-bottom: 0.5625rem;
}

.p-datatable .p-paginator-top {
    border: none;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    height: 1rem;
}


.p-datatable .p-paginator-top:hover {
    border: 1px solid var(--gray-170);
    color:  var(--primary-color);
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    min-width: 1.25rem;
    height: 1.25rem;
    background-color: var(--primary-color);
    color: white;
    font-weight: 400;
}

.p-paginator-page, .p-paginator-next, .p-paginator-last, .p-paginator-first, .p-paginator-prev, .p-paginator-current {
    align-items: unset;
}
.p-datatable .p-paginator-top:hover{
    border: none;
}
.p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 1rem;
    height: 1rem;
    color: var(--primary-color);
    font-weight: 400;
}

.p-paginator {
    padding: 0;
}
.p-button.p-button-success {
    background: #00B189;
    border: #00B189;
}

.p-button.p-button-success:enabled:hover {
    background: #00B189;
    border: #00B189;
}
.p-button {
    border-radius: 2px;
}

.p-button.p-button-success.p-button-outlined,
.p-buttonset.p-button-success > .p-button.p-button-outlined,
.p-splitbutton.p-button-success > .p-button.p-button-outlined,
.p-fileupload-choose.p-button-success.p-button-outlined,
.st-secondary-buttons.p-button-outlined.p-st-secondary {
    background-color: white;
    color: var(--blue-150);
    border-color: var(--blue-150);
}

.p-fileupload > .p-button {
    padding-top: 5px;
    padding-bottom: 5px;
}

.p-fileupload > .p-button .p-focus,
.p-fileupload > .p-button:focus {
    box-shadow: none;
}

/*We need to use important as Prime React uses Important on its gaps. That makes impossible to remove important*/
/*Important is not a good practice in CSS */
.gap-1 {
    gap: 0.5rem!important;
}

.gap-3 {
    gap: 1rem!important;
}

.gap-5 {
    gap: 2rem!important;
}

.gap-7 {
    gap: 4rem!important;
}

.gap-8 {
    gap: 5rem!important;
}

.p-picklist-buttons span {
    font-weight: bolder;
}

.p-picklist-buttons button {
    background-color:  #2262a9;
    padding: 4px;
    height: 2.25rem;
    width: 3rem;
}

.p-picklist-buttons button:disabled {
    background-color:  #2262a9;
    opacity: 70%;
}
.p-picklist .p-picklist-filter-container {
    border: none;
    padding-bottom: 5px;
    padding-left: 0;
}

.p-picklist .p-picklist-buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
}

.p-picklist .p-picklist-list {
    height: 13rem;
    overflow: auto;
}

.p-picklist-list-wrapper {
    height: 100%;
    overflow: hidden;
}

.p-picklist .p-picklist-list .p-picklist-item {
    padding: 0.375rem 1.25rem;
    color: var(--gray-900);
}

.p-picklist .p-picklist-list .p-picklist-item div {
    font-size: 0.875rem;
}

.p-picklist-filter input {
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #ced4da;
}

.p-picklist-filter input:enabled:focus {
    box-shadow: none;
}
.p-picklist-filter input:active {
    border: none;
    outline: none;
    box-shadow: none;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    background-color: #3B82F6;
}

.p-radiobutton.p-highlight .p-radiobutton-box {
    background: white;
}

.p-radiobutton .p-radiobutton-box.p-highlight{
    background-color: white;
    border: 2px solid #3B82F6;
}

.font-14 {
    font-size: 0.875rem;
}

.text-black {
    color: black;
}

.h-36 {
    height: 2.25rem;
}

.w-36 {
    width: 2.25rem;
}

.mr-0125 {
    margin-right: 0.125rem;
}

.p-tag-value {
    font-size: 0.875rem;
    font-weight: 600;
}

.p-tag.p-tag-success{
    background-color: var(--success);
    height: 1.5rem;
    padding: 0 1rem;
}

.p-tag.p-tag-info{
    background-color: var(--info);
    height: 1.5rem;
    color: var(--gray-500);
    padding: 0 1rem;
}
.p-tag.p-tag-warn{
    background-color: var(--warn);
    height: 1.5rem;
    padding: 0 1rem;
}
.p-tag.p-tag-danger{
    background-color: var(--danger);
    height: 1.5rem;
    padding: 0 1rem;
}

.ql-snow strong, .ql-snow strong *{
    font-weight: bold;
}

input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button {
    display: none !important;
    -webkit-appearence: none !important;
    margin: 0 !important;
}

.p-datatable .p-datatable-tbody > tr > td {
    border-bottom: none;
}

.p-datatable .p-datatable-thead > tr > th {
    border-bottom: none;
}

.p-datatable-with-child .p-datatable-tbody > tr > td {
    border-bottom: none;
}

.p-datatable-with-child .p-datatable-tbody > tr:first-child {
    border-right: 1px solid var(--gray-170);
    border-left: 1px solid var(--gray-170);
    border-bottom: none;
}

.p-datatable-with-child .p-datatable-tbody > tr:not(:first-child){
    border-bottom: none;
}

.p-datatable-row-expansion{
    border-bottom: 1px solid var(--gray-170) !important;
}

.p-multiselect {
    border: none;
    border-bottom: 1px solid var(--gray-170);
    border-radius: 0;
}

.badge-simple {
        background: #E0E5EE;
        color: #54698D;
}

.badge-success {
    background: var(--success);
    color: white;
}

.badge-alert {
    background: var(--danger);
    color: white;
}

.badge-warning {
    background: var(--warn);
    color: white;
}

.badge-text {
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1.4;
}

.badge {
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 10px;
    padding: 0 0.5rem;
}

.p-datatable .p-datatable-tfoot > tr > td {
    background-color: #ffffff;
    border: none;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
    background-color: #F4F6F9;
}
