.sortList-style{
    [data-pc-section="controls"] {
        display: none;
    }
    [data-pc-section="item"] {
        border-bottom: 1px solid #e6e6e6;
        padding: 0.2rem 1rem;
    }
    [data-pc-section="item"]:last-child {
        border-bottom: none;
    }
    [data-pc-section="list"] {
        padding: 0;
    }
    [data-p-orderlist-droppoint-highlight="true"] {
      
        height: 2rem;
    }
}