#switch .p-inputswitch .p-inputswitch-slider:before {
    background: var(--primary-color);
}

#switch .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: var(--primary-color);
}

#switch .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: white;
}

#switch .p-inputswitch .p-inputswitch-slider {
    background: white;
}

#switch .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: white;
}

#switch .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: white;
}

@media screen and (max-width: 500px) {
    .service-team-container {
        padding-left: 7.5rem;
    }
    .toggle-text {
        font-weight: normal;
        font-size: 1rem;
        color: white;
        width: 2rem;
        margin-left: 0.5rem;
        margin-right: 1rem;
    }
}
@media screen and (min-width: 500px) {
    .toggle-text {
        font-weight: bold;
        font-size: 1.3125rem;
        color: white;
        width: 5rem;
        margin-right: 0;
    }
    .toggle-text-right{
        margin-left:0.5rem;
    }
}
