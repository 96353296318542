.shake {
    animation: shake1 0.7s both;
    animation-iteration-count: infinite;
    border: 2px solid var(--blue-660);
}

@keyframes shake1 {
    0% {
        transform: rotate(-1deg);
        animation-timing-function: ease-in;
    }

    50% {
        transform: rotate(1deg);
        animation-timing-function: ease-out;
    }
}
