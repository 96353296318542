.hamburger-container {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 4px;
    left: 1px;
    padding-right: 1rem;
    background-color: transparent;
    z-index: 1;
    user-select: none;
    cursor: pointer;
}

.hamburger-layer {
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}
