.circle {
    width: 1.5rem;
    height: 1.5rem;
    background-color: var(--primary-color);
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow {
    background-color: transparent;
    border-top: 3px white solid;
    border-right: 3px white solid;
    width: 0.5rem;
    height: 0.5rem;
    transform: rotate(45deg);
    transition: transform 0.3s ease-in-out;
    position: absolute;
}

.toggle {
    height: 2rem;
    width: 2rem;
    position: absolute;
    opacity: 0;
    z-index: 100;
}

.toggle:checked ~ * .circle .arrow {
    transform: rotate(135deg);
    transition: transform 0.3s ease-in-out;
}

.accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}
.toggle:checked ~ .accordion-content {
    max-height: 10rem;
    overflow-y: auto;
    transition: max-height 0.3s ease-in-out;
}

.title {
    color: black;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: normal;
}
