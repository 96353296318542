.panel {
    overflow: hidden;
}

.panel-header {
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
}

.panel-body {
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    background-color: white;
    padding: 1rem 1.5rem 1rem 1.5rem;
}

.simple-action-button{
    height: 2.25rem;
}
a {
    text-decoration: none;
}

.p-selectable-row[tabindex="0"] > * {
    background-color: var(--blue-100);
}

.p-datatable .p-datatable-tbody > tr > td > .p-column-title {
    font-weight: 600;
}

.workOrderHoursDialog {
    border-radius: 0.625rem;
}

.workOrderHoursDialog .p-dialog-header{
    border-top-right-radius: 0.625rem;
    border-top-left-radius: 0.625rem;
}

.workOrderHoursDialog .p-dialog-content:last-of-type{
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
}

.p-datatable .p-datatable-tfoot > tr > td {
    padding: 0.2rem 0.2rem;
}

.p-datatable .p-datatable-tfoot > tr > th {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
}

.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
    min-width: 2rem;
}

