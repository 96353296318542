.login-screen {
   width: 100vw;
   height: 100vh;
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   align-items: center;
   z-index: 1000;
}

.login-bg-image {
   z-index: 10;

   /* Set up proportionate scaling */
   width: 100%;
   height: 100%;

   /* Set up positioning */
   position: fixed;
   top: 0;
   left: 0;
}

.front {
   z-index: 100;
   position: relative;
}

.login-form {
   width: 23rem;
}

.login-form-big {
   width: 33rem;
}

.login-footer {
   top: 1.8rem;
}

@media screen and (max-width: 500px) {
}
