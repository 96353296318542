.simple-button-action {
    border-color: var(--primary-color);
    height: 2.25rem;
}

.simple-button-action-label {
    font-size: 0.8125rem;
}

.st-small-button {
    padding: 0.5rem 0.75rem
}
